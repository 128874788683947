<template>
    <div class="ratingList">
		<watch-head :title="$t('评分列表')" :img="require('@/assets/bracelet_img/38.png')" />
        
        <van-list 
            v-model="loading"
            :finished="finished"
            :finished-text="$t('没有更多了')"
            :loading-text="$t('加载中...')"
            :immediate-check="false"
            :offset="20"
            @load="onLoad"
            class="list"
        >
            <div class="main" @click="goTo('/myRating', item.id)" v-for="(item, index) in list" :key="index">
                <div class="roomTitle">{{ item.name ? item.name : $t('暂无名称') }}</div>
                <div class="socialRating">{{$t('我的评分')}} :</div>
                <van-rate v-model="item.grade" readonly :size="40" color="#a7f264" void-color="#2a2a2a" />
            </div>
        </van-list>
        <!-- <div class="btm">
			<div class="left">
				<div class="box">
					<img src="@/assets/bracelet_img/5.png" alt="">
				</div>
			</div>
			<div class="right">
				<div class="ask">请求</div>
				<div class="invite">邀请</div>
			</div>
		</div> -->
    </div>
</template>

<script>
	import watchHead from '@/components/watchHead/index.vue'
    import { ScoredListApi } from '@/api/api'
    import { mapState } from 'vuex'
	export default {
		components: {
			watchHead
		},
		data() {
			return {
                value: 0,
                timer: null,
                loading: false,
                finished: false,
                page: 1,
                limit:10,
                list: [],
			}
		},
        computed: {
			...mapState([
				'web3',
			]),
		},
		created(){ this.waitForGlobal(); },
		methods: {
            onLoad () {
                ScoredListApi({
                    page: this.page,
                    limit:this.limit,
                }).then(res => {
                    if (res.code == 0) {
                        for (let index = 0; index < res.data.data.length; index++) {
                            const element = res.data.data[index];
                            element.score = Number(element.score)
                        }
                        this.list = this.list.concat(res.data.data)
                        this.loading = false;
                        if (res.data.data.length < this.limit) {
                            this.finished = true;
                        }
                        this.page++
                    } else {
                        this.finished = true;
                    }
                })
            },
            goTo(url, id){
				this.$router.push({
					path: url,
                    query: {
                        id
                    }
				})
			},
            //链接钱包
			waitForGlobal: function () {
				window.clearTimeout(this.timer);
				// 1. 刷新页面时是否已经连接钱包登录
				if (localStorage.getItem('Token') && this.web3 && localStorage.getItem('defaultAddress') && localStorage.getItem('userInfo')) {
					window.clearTimeout(this.timer);
					this.loading = true
                    this.finished = false
                    this.list = []
                    this.page = 1
                    this.onLoad();
				} else {
					// 如果检测到没有连接钱包登录
					this.timer = setTimeout(
						this.waitForGlobal
					, 100);
				}
			},
		}
	}
</script>

<style lang="less" scoped>
    .ratingList{
        width: 100%;
		min-height: 100vh;
		padding-top: 75px;
		padding-bottom: 67.5px;
		background: url(~@/assets/bracelet_img/8.png);
		background-size: 100%;
		color: #ffffff;
		background-repeat: repeat-y;
        .list{
            padding: 30px 33.75px 0;
            display: flex;
            flex-direction: column;
            align-items: center;

            scrollbar-width: none; /* Firefox */
            -ms-overflow-style: none; /* IE 10 */
            .main{
                width: 100%;
                background-color: rgba(0, 0, 0, 0.4);
                border-radius: 7.5px;
                position: relative;
                padding: 11.25px 11.25px 18.75px;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 15px;
                .roomTitle{
                    font-family: 'TsangerYuYangT-W05';
                    font-size: 25px;
                    width: 100%;
                    margin-bottom: 15px;
                }
                .socialRating{
                    width: 100%;
                    font-family: '071-SSRuiFengTi';
                    font-size: 18px;
                    margin-bottom: 11.25px;
                }
                
            }
        }
        .list::-webkit-scrollbar {
            display: none;
        }

		// .btm{
		// 	width: 100%;
		// 	display: flex;
		// 	align-items: center;
		// 	position: fixed;
		// 	bottom: 22.5px;
		// 	.left{
		// 		background-color: rgba(43, 43, 43, 0.5);
		// 		border-radius: 0 3px 3px 0;
		// 		padding: 3px 3px 3px 0;
		// 		margin-right: 3px;
		// 		.box{
		// 			padding: 3px 10px 3px 7.5px;
		// 			background-color: rgba(43, 43, 43, 0.9);
		// 			border-radius: 0 3px 3px 0;
		// 			border: solid 2px #7736fe;
		// 			border-left: none;
		// 			display: flex;
		// 			img{
		// 				width: 26px;
		// 				height: 18.75px;
		// 			}
		// 		}
		// 	}
		// 	.right{
		// 		background-color: rgba(43, 43, 43, 0.5);
		// 		border-radius: 3px 26.25px 3px 3px;
		// 		padding: 3.75px 20px 3.75px 7.5px;
		// 		display: flex;
		// 		align-items: center;
		// 		font-family: 'REEJI-PinboGB-Flash';
		// 		font-size: 18.75px;
		// 		color: #2b2b2b;
		// 		.ask{
		// 			background-color: #5c65cb;
		// 			box-shadow: 0px 0px 0px 0px rgba(19, 19, 19, 0.16);
		// 			border-radius: 18.75px 3px 18.75px 3px;
		// 			padding: 2.5px 48.75px;
		// 			margin-right: 7.5px;
		// 		}
		// 		.invite{
		// 			background-image: linear-gradient(#a7f264, #a7f264), 
		// 			linear-gradient(#5c65cb, #5c65cb);
		// 			background-blend-mode: normal, normal;
		// 			box-shadow: 0px 0px 0px 0px rgba(19, 19, 19, 0.16);
		// 			border-radius: 18.75px 3px 18.75px 3px;
		// 			padding: 2.5px 48.75px;
		// 		}
		// 	}
		// }
    }
</style>